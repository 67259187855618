<template>
    <div class="to_confirm">
        <div>录入设备维修单</div>
        <!-- 设备选择 -->
        <van-grid :column-num="1" :gutter="10" class="device_info">
            <van-grid-item>
                <!-- <div class="item_style">{{ `编号:01` }}</div>
                <div class="item_style">{{ `A区1线-组框机1号-ABCABCAB2023/10/2310:42已持续20分钟组件吸附异常/破真空异常` }}</div> -->
                <div class="item_style">{{ `编号:${maintenanceItem.id}` }}</div>
                <div class="item_style">{{ `${maintenanceItem.info}` }}</div>
            </van-grid-item>
        </van-grid>
        <div class="form_label">维修时间</div>
        <el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <!-- 维修人 -->
        <div class="form_label">维修人</div>
        <el-autocomplete class="inline-input" v-model="maintenancePersonnel" :fetch-suggestions="querySearch"
            placeholder="请选择维修人" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
        <!-- 确认故障类型 -->
        <div class="form_label">故障类型</div>
        <el-select v-model="faultType" placeholder="请确认故障类型">
            <el-option v-for="item in maintenanceList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
        </el-select>
        <!-- 故障原因 -->
        <div class="form_label">故障原因</div>
        <el-select v-model="faultReason" placeholder="请选择故障原因">
            <el-option v-for="item in resList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
        </el-select>
        <!-- 所选维修方案 -->
        <div class="form_label">所选维修方案</div>
        <el-select v-model="maintenancePlan" placeholder="请选择维修方案">
            <el-option v-for="item in planList" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
        </el-select>
        <!-- 维修方案补充 -->
        <div class="form_label">维修方案补充</div>
        <el-input type="textarea" autosize placeholder="维修方案补充说明" v-model="supplementText">
        </el-input>
        <!-- 操作按钮 -->
        <div class="opreation_button">
            <van-button type="primary" @click="onConfirm">保存</van-button>
            <van-button type="default" @click="onCancel">取消</van-button>
        </div>
        <van-dialog v-model="cancelDialog" title=" " show-cancel-button @confirm="cancelConfirm" @cancel="cancelClose">
            <div>确认要取消填写么</div>
        </van-dialog>
    </div>
</template>

<script>
import Vue from 'vue'

import { Grid, GridItem, Button, Notify, Dialog } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(Notify);
Vue.use(Dialog);

export default {
    data() {
        return {
            cancelDialog: false,
            restaurants: [],
            maintenanceItem: {},
            maintenancePersonnel: sessionStorage.getItem('username'),
            maintenancePlan: '',
            isConfirm: false,
            time: [],
            resList: [
                {
                    value: '选项1',
                    label: '归正未调好'
                }, {
                    value: '选项2',
                    label: '吸真空异常'
                }
            ],
            planList: [
                {
                    value: '选项1',
                    label: '方案1'
                }, {
                    value: '选项2',
                    label: '方案2'
                }
            ],
            userList: [{
                value: '选项1',
                label: '员工一'
            }, {
                value: '选项2',
                label: '员工二'
            }],//人员列表
            isRight: [
                {
                    value: true,
                    label: '是'
                }, {
                    value: false,
                    label: '否'
                }
            ],
            maintenanceList: [{
                value: '选项1',
                label: '线体气缸上位异常'
            }, {
                value: '选项2',
                label: '长边1边框归正紧位异常'
            }, {
                value: '选项3',
                label: '短边1夹抓松位异常'
            }],
            faultType: '',
            faultReason: '',
            supplementText: '',
            isRepair: '',
        }
    },
    methods: {
        onConfirm() {
            console.log(this.maintenancePersonnel);
            if (this.time.len != 0 && this.maintenancePersonnel != '' && this.faultType != '' && this.faultReason != '' && this.faultType != '' || this.supplementText != '') {
                let id = this.$route.query.id
                // 更改已确认
                let faultList = JSON.parse(sessionStorage.getItem('maintenanceData'))
                faultList.find(item => {
                    if (item.id == id) {
                        item.isRepair = true
                    }
                })
                console.log(faultList);
                sessionStorage.setItem('maintenanceData', JSON.stringify(faultList))
                this.$router.push({
                    path: '/maintenancesus',
                    query: { id: id }
                })
            } else {
                Notify('请填写完整的确认设备故障单')
            }
        },
        // 点击取消
        onCancel() {
            this.cancelDialog = true
        },
        // 取消操作弹窗确认
        cancelConfirm() {
            this.time = []
            this.maintenancePersonnel = ''
            this.faultType = ''
            this.faultReason = ''
            this.maintenancePlan = ''
            this.supplementText = ''
            this.$router.push('/maintenancelist')
        },
        // 取消操作弹窗取消
        cancelClose() { },
        // 人员搜索
        // 人员搜索
        async querySearch(queryString, cb) {
            // 请求用户数据
            await this.$axios.get("http://" + this.ip + "/python/User/?search=" + queryString).then(
                (response) => {
                    console.log(response.data.res);
                    response.data.res.map(item => {
                        return item.value = item.name
                    })
                    cb(response.data.res)
                },
                (error) => {
                    console.log(error.message);
                    this.$message.error(this.$t("msg.user_info_f"));
                }
            );
            // var restaurants = this.restaurants;
            // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // // 调用 callback 返回建议列表的数据
            // cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        loadAll() {
            return [
                { "value": "黄晨晨" },
                { "value": "王子寒" },
            ];
        },
        handleSelect(item) {
            console.log(item);
        }

    },
    created() {
        // console.log(this.$route.params.maintenanceItem);
    },
    mounted() {
        this.restaurants = this.loadAll();
        let id = this.$route.query.id
        this.maintenanceItem = JSON.parse(sessionStorage.getItem('maintenanceData')).find(item => item.id == id)
    }
}
</script>

<style lang="scss" scoped>
.to_confirm {
    background-color: #f7f8fa;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.25rem;

    .device_info {
        margin: 0 .625rem;
        height: 5rem;
        margin-top: .625rem;

        .item_style {
            font-size: 0.75rem;
            text-align: left;
            word-break: break-all;
            width: 100%;
        }
    }

    .form_label {
        text-align: left;
        padding-left: 1.25rem;
        padding-bottom: 0.625rem;
        padding-top: 0.625rem;
        font-size: 0.75rem;

    }

    .el-select {
        width: calc(100% - 2.5rem);
    }

    .el-input {
        width: calc(100% - 2.5rem);
    }

    .el-autocomplete {
        width: calc(100% - 2.5rem);
    }

    .el-range-editor.el-input__inner {
        width: calc(100% - 2.5rem);
    }

    ::v-deep .el-textarea__inner {
        width: calc(100% - 2.5rem);
        resize: none;
        margin-left: 1.25rem;
    }

    .opreation_button {
        position: fixed;
        bottom: 0;
        width: 100%;

        .van-button {
            border-radius: 0;
            width: 50%;
        }
    }
}
</style>
<style>
.el-date-range-picker .el-picker-panel__body {
    min-width: 100%;
}

.el-date-range-picker__content {
    width: 100% !important;
}

.el-date-range-picker {
    width: 80% !important;
}

.el-date-range-picker__content {
    margin: 0px;
    padding: 5px;
}

.el-date-range-picker__content.is-left {
    padding-bottom: 0px;
}

.el-date-range-picker__content.is-right {
    padding-top: 0px;
}

.el-date-table th {
    padding: 0px;
}

.el-date-table td {
    padding: 0px;
}

.el-date-range-picker__time-picker-wrap .el-time-panel {
    right: -2.0625rem;
    left: unset;
}
</style>